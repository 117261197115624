$roboto-font: 'Roboto', sans-serif;
$font-manrope: 'Manrope', sans-serif;
$title-font: 'Kdam Thmor Pro', sans-serif;
$font-mont: 'Montserrat', sans-serif;
$font-mono: 'Roboto Mono', monospace;
$font-services: 'Play', sans-serif;

$bg: rgb(255, 255, 255);
$bg2: rgb(0, 0, 0);
$text: rgb(15, 15, 15);
$text2: rgb(240, 240, 240);
$primary: rgb(22, 210, 247);

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$header-hight: 150px;

$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;
$xl: 1800px;

@mixin breakpoint($breakpoint) {
    @if $breakpoint == mobile {
        @media (max-width: #{$mobile}) {
            @content;
        }
    } @else if $breakpoint == tablet {
        @media (max-width: #{$tablet}) {
            @content;
        }
    } @else if $breakpoint == desktop {
        @media (max-width: #{$desktop}) {
            @content;
        }
    } @else if $breakpoint == xl {
        @media (max-width: #{$xl}) {
            @content;
        }
    }
}
