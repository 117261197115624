@import '../../styles/variables.scss';

.header {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px 32px;
    box-shadow: 0 2px 5px red;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;

        pointer-events: none;
    }
    .app-mobilescreen {
        display: none;
    }

    .logo-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;

        img.logo-no-background {
            width: 245px;
            max-height: 100%;
        }
    }

    @include breakpoint(tablet) {
        padding: 16px;
        .app-mobilescreen {
            display: inline-block;
        }
        .logo-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img.logo-no-background {
                width: 200px;
                max-height: 100%;
            }
        }
    }
    @include breakpoint(mobile) {
        padding: 16px;
        .app-mobilescreen {
            z-index: 10;
            display: flex;
            align-items: center;

            p {
                color: white;
                font-size: 18px;
            }
        }
        .logo-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img.logo-no-background {
                width: 200px;
                max-height: 100%;
            }
        }
    }

    @include breakpoint(desktop) {
        padding: 0px;
    }
}
