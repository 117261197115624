@import '../../../styles/variables.scss';
.form-div {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 5px red;
    height: fit-content;
    border-radius: 10px;
    width: 500px;
    margin-top: 15px;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        border-radius: 10px;
        background-color: black;
        opacity: 0.8;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: -1;

        @include breakpoint(desktop) {
            opacity: 0.9;
        }

        @include breakpoint(tablet) {
            opacity: 0.9;
        }

        @include breakpoint(mobile) {
            opacity: 0.9;
        }
    }

    @include breakpoint(desktop) {
    }

    @include breakpoint(mobile) {
        width: 370px;
    }

    @include breakpoint(tablet) {
    }

    .close-button-div {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .close-button {
            font-size: 25px;
            height: 50px;
            width: 50px;
            background-color: #ed4747;
            color: white;
            border: 1px solid #ed4747;
            border-radius: 10px;

            &:hover {
                opacity: 0.9;
            }
            &:active {
                height: 48px;
                width: 48px;
            }
        }
    }
    .contact-form {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        padding: 0px 10px 10px 10px;
        height: fit-content;

        @include breakpoint(mobile) {
            width: 375px;
        }

        h3 {
            align-self: center;
            font-size: 25px;
            color: white;
        }
        textarea {
            padding: 10px 10px 10px 10px;
            border-radius: 10px;
        }
        .form-group {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
                font-weight: bold;
                color: white;
            }
            input {
                padding: 10px 10px 10px 10px;
                width: 100%;
                border-style: none;
                border-radius: 10px;

                @include breakpoint(tablet) {
                    padding: 10px 10px 10px 10px;
                    border-radius: 5px;
                }
            }

            .form-select {
                display: flex;
                padding: 10px 10px 10px 10px;
                width: 100%;
                border-radius: 10px;
            }
        }

        .form-button-div {
            width: 100%;

            height: fit-content;
            .form-button {
                width: 100%;
                height: 50px;
                border-style: none;
                border-radius: 10px;
                color: white;
                background-color: #ed4747;
            }
        }
    }
}
