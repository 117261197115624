@import '../../styles/variables.scss';

.app-navbar {
    width: 100%;
    height: 0px;
    display: flex;

    justify-content: center;
    padding-top: 10px;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    .ul-menu {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;

        .ul-li {
            margin: 0px 16px;
            cursor: pointer;
            font-size: 23px;
            font-weight: 600;

            .li-link {
                color: rgb(255, 255, 255);
                text-decoration: none;

                transition: 0.5s ease;

                &:hover {
                    color: rgb(235, 153, 153);
                    border-bottom: 1px solid red;
                }
            }
        }
    }

    @include breakpoint(tablet) {
        .ul-menu {
            display: none;
        }

        &.mobile-menu-open {
            .ul-menu {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: black;
                position: absolute;
                top: 140px;
                left: 0;
                width: 100%;
            }

            .ul-li {
                margin: 10px 0;
            }
        }
    }

    @include breakpoint(mobile) {
        .ul-menu {
            display: none;
        }

        &.mobile-menu-open {
            z-index: 11;

            .ul-menu {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: rgb(22, 18, 18);
                position: absolute;
                top: 140px;
                left: 0;
                width: 100%;
                box-shadow: 0 2px 5px red;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .ul-li {
                margin: 10px 0;
            }
        }
    }
}
