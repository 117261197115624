@import './variables.scss';

*,
*::before,
*::after {
    margin: 0px;
    box-sizing: border-box;
}

body {
    font-family: $font-manrope;
    color: $text;
    height: fit-content;
}
