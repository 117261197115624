@import '../../styles/variables.scss';

.about-div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 15px 10px 15px 10px;
    gap: 30px;

    @include breakpoint(tablet) {
        align-items: start;
    }
    .about-img-div {
        grid-area: 1 / 1 / 6 / 3;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include breakpoint(tablet) {
            grid-area: 2 / 1 / 4 / 6;
            justify-content: center;
        }

        .about-img {
            height: 500px;
            width: 530px;

            @include breakpoint(desktop) {
                height: 500px;
                width: fit-content;
            }

            @include breakpoint(tablet) {
                height: 540px;
                width: 500px;
            }
            @include breakpoint(mobile) {
                height: 340px;
                width: 370px;
            }
        }
    }
    .about-grid-h2 {
        grid-area: 1 / 3 / 2 / 6;
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
            grid-area: 1 / 1 / 2 / 6;
            align-self: flex-end;
            justify-content: center;
        }

        .about-h2 {
            font-size: 60px;
            color: rgb(0, 0, 0);
        }
    }

    .about-us-text {
        grid-area: 2 / 3 / 6 / 6;
        display: flex;

        @include breakpoint(tablet) {
            grid-area: 4 / 1 / 6 / 6;
            justify-content: center;
        }

        .about-p {
            color: rgb(0, 0, 0);
            font-size: 20px;
            @include breakpoint(desktop) {
                width: 80%;
            }
        }

        @include breakpoint(tablet) {
            .about-p {
                width: 85%;
                word-spacing: 3px;
                color: rgb(0, 0, 0);
                font-size: 17px;
            }
        }
    }
}
