@import '../../styles/variables.scss';

.contact-page {
    background-image: url(../../assets/images/contactbg1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: fit-content;
    padding-bottom: 50px;
    .contact-section {
        .contact-title-div {
            height: 450px;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            @include breakpoint(tablet) {
                width: 370px;
            }

            .h1-div {
                padding: 24px;
                width: 1100px;
                height: 360px;
                align-items: center;
                justify-content: center;
                @include breakpoint(tablet) {
                    width: 370px;
                    padding: 15px;
                }
                h1 {
                    font-size: 80px;
                    color: #ffff;

                    @include breakpoint(tablet) {
                        font-size: 60px;
                    }
                }
            }
        }

        .contact-form-with-info {
            color: #ffff;
            background-color: #000000;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: 100%;
            overflow: hidden;

            @include breakpoint(tablet) {
                flex-direction: column;
                gap: 20px;
            }

            .contact-footer-div,
            .contact-footer-form-div {
                display: flex;
                justify-content: center;
                width: 574px;
                height: 700px;
                @include breakpoint(tablet) {
                    width: 370px;
                    height: fit-content;
                }
            }

            .contact-footer-form-div {
                color: #ffff;

                align-items: center;
            }

            .contact-footer-div {
                flex-direction: column;
                gap: 40px;
                width: fit-content;
                padding: 24px;

                @include breakpoint(tablet) {
                    .contacts-h2-div {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .contact-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;

                    .contact-info-inside-div {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }
            }
        }
    }
}
